<template>
  <Balloon top elevated>
    <div class="c-impressionBalloon">
      <Icon
        name="faceGood"
        class="c-impressionBalloon__icon c-impressionBalloon__icon--good"
        color="accent02" />
      <span
        class="c-impressionBalloon__counter c-impressionBalloon__counter--good">
        {{ like }}
      </span>
      <Icon
        name="faceBad"
        class="c-impressionBalloon__icon c-impressionBalloon__icon--bad"
        color="grey01" />
      <span
        class="c-impressionBalloon__counter c-impressionBalloon__counter--bad">
        {{ hate }}
      </span>
    </div>
  </Balloon>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    like: {
      type: Number,
      default: 0
    },

    hate: {
      type: Number,
      default: 0
    }
  }
});
</script>
