var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Balloon", { attrs: { top: "", elevated: "" } }, [
    _c(
      "div",
      { staticClass: "c-impressionBalloon" },
      [
        _c("Icon", {
          staticClass:
            "c-impressionBalloon__icon c-impressionBalloon__icon--good",
          attrs: { name: "faceGood", color: "accent02" },
        }),
        _c(
          "span",
          {
            staticClass:
              "c-impressionBalloon__counter c-impressionBalloon__counter--good",
          },
          [_vm._v(" " + _vm._s(_vm.like) + " ")]
        ),
        _c("Icon", {
          staticClass:
            "c-impressionBalloon__icon c-impressionBalloon__icon--bad",
          attrs: { name: "faceBad", color: "grey01" },
        }),
        _c(
          "span",
          {
            staticClass:
              "c-impressionBalloon__counter c-impressionBalloon__counter--bad",
          },
          [_vm._v(" " + _vm._s(_vm.hate) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }